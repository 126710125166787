import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

import { connect } from 'react-redux';

// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

import Layout from '../../components/layout/Layout';

// Actions - imported
import { toggleSideNav, setPage, add_To_Recent_History } from '../../actions/navActions';
import { getUserNotifications, markNotificationRead, setNotifications } from '../../actions/notificationActions';

// Components - imported
import Notification from '../../components/Notification/Notification';
import Spinner from '../../components/common/Spinner';

// Icons - imported
import CommentIcon from '@material-ui/icons/Comment';
import NotificationsIcon from '@material-ui/icons/Notifications';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MailIcon from '@material-ui/icons/Mail';
import AddIcon from '@material-ui/icons/Add';

const Notifications = ({ 
    toggleSideNav, 
    setPage, 
    add_To_Recent_History,
    getUserNotifications, 
    setNotifications,
    markNotificationRead, 
    nav: { 
        sideNav 
    }, 
    auth, 
    notification: { 
        notifications, 
        no_more_notifications,
        loading 
    } 
}) => {
    
    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Check if user is verified
    const [checkedVerification, setCheckedVerification] = useState(false);
    const [checkedVerification_schoolEmail, setCheckedVerification_schoolEmail] = useState(false);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Inifite scroll
    const [lastPageDoc, setLastPageDoc] = useState(null);
    const [showBottomSpinner, setShowBottomSpinner] = useState(false)

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {

        // Set the current page to "notifications"
        setPage("notifications");
        add_To_Recent_History(url.pathname);  // current url
        
        // Fetch user notifications if the user is authenticated
        if(auth.user) {
            getUserNotifications();
        } else {
            setNotifications([]);
        }
    }, [auth.user]);

    useEffect(() => {

        if(notifications.length > 0) {
            handleLastLoadedDoc();
        }
    }, [notifications]);

    useEffect(() => {

        // Mark unread notifications as read when the notifications array changes
        if(notifications.length > 0) {
            notifications.map(notification => {
                if(!notification.read) {
                    markNotificationRead(notification._id);
                }
            })
        }
    }, [notifications]);

    const handleLastLoadedDoc = async () => {
        let lastDocument = notifications[notifications.length - 1];

        // Create a reference to the specified post document in the "notifications" collection
        const docRef = doc(db, 'notifications', lastDocument?._id)

        // Retrieve the document with the specified ID from the "notifications" collection
        const notificationDoc = await getDoc(docRef);

        // Get the last visible document for the next load
        setLastPageDoc(notificationDoc);

        // Hide the loading spinner
        setShowBottomSpinner(false);
    }

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Check if user is verified. If no redirect to verify page
    if(auth.user && !auth.user.verified && !checkedVerification) {
        window.location.href = '/verify';

        setCheckedVerification(true);
    }

    // Check if user's school email is verified. If no redirect to school email page
    if(auth.user && !auth.user.school_verified && !checkedVerification_schoolEmail) {
        window.location.href = '/settings/account/school_email';

        setCheckedVerification_schoolEmail(true);
    }

    
    let notificationList;

    if(notifications === null || loading) {

        // Display a Spinner component while loading notifications
        notificationList = <Spinner />;
    }
    else {
        if(notifications.length > 0) {

            // Render each notification based on its type
            notificationList = notifications.map(notification => {
                if(notification.type === 'like') {

                    // Display a notification for a like
                    return (
                        <Notification 
                            key={notification._id} 
                            notification={notification} 
                            Icon={FavoriteIcon}
                            headerText="liked your post"
                            
                        />
                    )
                } else if(notification.type === 'comment') {

                    // Display a notification for a comment
                    return (
                        <Notification 
                            key={notification._id} 
                            notification={notification} 
                            Icon={CommentIcon}
                            headerText="commented on your post:"
                        />
                    )
                } else if(notification.type === 'comment reply') {

                    // Display a notification for a comment
                    return (
                        <Notification 
                            key={notification._id} 
                            notification={notification} 
                            Icon={CommentIcon}
                            headerText="replied to your comment:"
                        />
                    )
                } else if(notification.type === 'comment interaction') { // Someone replied to a sub-comment of your comment

                    // Display a notification for a comment
                    return (
                        <Notification 
                            key={notification._id} 
                            notification={notification} 
                            Icon={CommentIcon}
                            headerText="interacted with your comment:"
                        />
                    )
                } else if(notification.type === 'post interaction') { // Someone replied to a sub-comment on your post

                    // Display a notification for a comment
                    return (
                        <Notification 
                            key={notification._id} 
                            notification={notification} 
                            Icon={CommentIcon}
                            headerText="replied to a comment on your post:"
                        />
                    )
                } else if(notification.type === 'message') {

                    // Display a notification for a message
                    return (
                        <Notification 
                            key={notification._id} 
                            notification={notification} 
                            Icon={MailIcon}
                            headerText="sent you a message:"
                        />
                    )
                } else if(notification.type === 'message like') {

                    // Display a notification for a message like
                    return (
                        <Notification 
                            key={notification._id} 
                            notification={notification} 
                            Icon={MailIcon}
                            headerText="liked a message:"
                        />
                    )
                } else if(notification.type === 'new chat') {

                    // Display a notification for a message
                    return (
                        <Notification 
                            key={notification._id} 
                            notification={notification} 
                            Icon={AddIcon}
                            headerText="started a conversation with you."
                        />
                    )
                }
            })
        }
        else {

            // Display a message when there are no notifications
            notificationList = (
                <div className="no-posts-container">
                    <div className="no-chats">
                        <h1>Nothing to see here - yet</h1>
                        <p>Once you start buying and selling, your notifications will show up here.</p>
                    </div>
                </div>
            );
        }
    }

    // Handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight } = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {
    
                // scrolling up - show top nav
                setScrollDirection("up");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

        // Check if the user has scrolled to the bottom of the content and conditions for loading more notifications are met
        if (newScrollY >= scrollHeight - 1 && !showBottomSpinner && !no_more_notifications && notifications.length > 0) {
            console.log('scrolling');
            loadMore();
        }
    }

    // Function to load more paginated notifications
    const loadMore = () => {
        console.log("last", lastPageDoc);

        // Show spinner
        setShowBottomSpinner(true);

        getUserNotifications(lastPageDoc);

    }

    // Initialize Mixpanel and track the "Notifications Page View" event
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.identify(auth.user._id);
        mixpanel.track("Notifications Page View");
    }

    // Send Mixpanel analytics if in production and the user is authenticated
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && auth.user !== null) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    return (

        // Render the Notifications page layout
        <Layout handleScroll={handleScroll} sideNav={sideNav} handleSlideMenu={toggleSideNav} page="notifications" notificationsPage>
            {notificationList}

            {/* Render a spinner at the bottom if there are more notifications to load */}
            {showBottomSpinner && !no_more_notifications && <Spinner />}
            
            {/* Display a message when there are no more notifications */}
            <div className="no-rides">{(no_more_notifications && notifications.length !== 0) && "You've reached the end."}</div>
        </Layout>
    )
}

Notifications.propTypes = {
    toggleSideNav: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    getUserNotifications: PropTypes.func.isRequired,
    setNotifications: PropTypes.func.isRequired,
    markNotificationRead: PropTypes.func.isRequired,
    nav: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    nav: state.nav,
    auth: state.auth,
    notification: state.notification
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    toggleSideNav, 
    setPage, 
    add_To_Recent_History,
    getUserNotifications, 
    setNotifications,
    markNotificationRead 
})(Notifications);
