import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { login, loginWithEmailAndPassword } from '../../actions/authActions';
import { setAlert } from '../../actions/alertActions';

// Layout Wrapper
import ModalPage from '../../components/layout/ModalPage';

// Components - imported
import { Button } from '@material-ui/core';
import Alert from '../../components/common/Alert';
import GoogleBtn from '../../components/common/GoogleBtn';

// Icons -imported
import TwitterIcon from '@material-ui/icons/Twitter';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Logo from '../../components/common/Logo';

import campus_logo from '../../utils/imgs/campusbuysell_logo.png';
import ou_logo from '../../utils/imgs/ou_logo.png';
import ut_logo from '../../utils/imgs/ut_logo.png';
import smu_logo from '../../utils/imgs/smu_logo.png';
import uf_logo from '../../utils/imgs/uf_logo.png';

// Banned Email list
import { bannedEmails } from '../../utils/banned_emails/bannedEmails';

// -- Security Logic ---

const GEO_LOCATION_URL = `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IP_GEOLOCATION_apiKey}&ip=`;

// -- END: Security Logic --


// Initial State
const initialState = {
    email: '',
    password: ''
}

const Login = ({
    auth: { 
        isAuthenticated, 
        user,
        account_loading
    }, 
    nav: {
        campus_name,
        campus_id,
        campus_ext
    },
    loginWithEmailAndPassword,
    setAlert,
    history
}) => {

    // State for tracking if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);
    
    // State for tracking the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // State for form data
    const [formData, setFormData] = useState(initialState);

    // State to toggle login methods
    const [loginWithPassword, setLoginWithPassword] = useState(true);

    // State to toggle email code inputs
    const [showEmailCode, setShowEmailCode] = useState(false);

    // Get the URL filter and check if there is a "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("redirect");
    const authMethod = url.searchParams.get("method");
    const url_Email = url.searchParams.get("email");

    useEffect(() => {
        
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Handle the "redirect" parameter in the URL
    useEffect(() => {
        if(filter && filter === 'true') {
            setAlert('Failed: Wrong Email.', 'danger')
        } 

    }, [filter]);

    // Handle the Authentication Method via URL parameter
    useEffect(() => {
        if(authMethod && authMethod === 'password') {
            setLoginWithPassword(true)
        } 

        if(authMethod && authMethod === 'noPassword') {
            setLoginWithPassword(false)
        } 
    }, [authMethod]);

    useEffect(() => {
        if(url_Email) {
            console.log('URL EMAIL EXISTS')
            console.log(url_Email)
            setFormData({ ...formData, ['email']: url_Email});
        } 

    }, [url_Email]);

    // Function to handle window size change
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Function to go back in history
    const goBack = (e) => {
        history.goBack();
    }

    // Check if the device is mobile or tablet
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Destructure the email value from form data
    const { email, password } = formData;

    // Function to handle input change
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});

    // Function to validate email format
    const validateEmail = (input) => {

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      
        if (input.match(validRegex)) {
      
          return true;
      
        } else {
      
          return false;
      
        }
    }

    

    const handleEmailSignInLink = e => {
        e.preventDefault();
        
        if(email === '') {
            setAlert('Email is required.', 'danger');
        } else if (!validateEmail(email)) {
            setAlert('Invalid email address!', 'danger');
        } else {

            // Redirect to login link page with the provided data
            window.location.href = `/login/sent?email=${email}`;
        }
    }

    // Function to handle Password form submission
    const onSubmit = async e => {
        e.preventDefault();

        try {

            // -- Security Check -- 

            // Get IP address
            const response = await fetch('https://api.ipify.org');
            const ipAddress = await response.text();

            const ip_data = await fetch(`${GEO_LOCATION_URL + ipAddress}`);
            const ip_data_obj = await ip_data.json();

            if(ip_data_obj.time_zone) {
        
                // -- Detect if using VPN --
                const real = getRealLocation()
                const simulated = ip_data_obj.time_zone.name;
        
                ip_data_obj.timezones_differ = real !== simulated;
                // -- END: Detect if using VPN --
            }

            if( ip_data_obj?.country_code2 ) {
            
                if(ip_data_obj.country_code2 !== 'US' || ip_data_obj.timezones_differ) {
                    window.location.href = '/404';
                    return;
                }
            }

            // -- END: Security Check --
            
            if(email === '') {
                setAlert('Email is required.', 'danger');
            } else if (!validateEmail(email)) {
                setAlert('Invalid email address!', 'danger');
            } else if (password === '') {
                setAlert('Password is required.', 'danger');
            } else if (bannedEmails.includes(email)) {
                setAlert('Account has been banned', 'danger');
            } else {

                // Login action
                console.log('START LOGIN');
                handleLogin(e);
                
            }

        } catch (error) {
            console.error('CODE 511', error);

            // Login action
            console.log('START LOGIN');
            handleLogin(e);
        }
    }

    // Function to handle Email Code verification & submission
    const onSubmitAuthCode = async e => {
        e.preventDefault();
        
        window.alert('Processing verification code...')
    }

    const handleLogin = (e) => {
        e.preventDefault();

        // Authenticate the user
        loginWithEmailAndPassword(email, password, campus_id);
    };

    // --- Site Security Functions ---

    function getRealLocation() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    
    // --- END: Site Security Functions ---

    // --- Campus Logic ----

    let app_logo;

    if(campus_name.toLowerCase() === 'campus') {
            
        app_logo = campus_logo
    }
    else if(campus_name.toLowerCase() === 'ou') {
            
        app_logo = ou_logo
    }
    else if(campus_name.toLowerCase() === 'ut') {
        app_logo = ut_logo
    }
    else if(campus_name.toLowerCase() === 'smu') {
        app_logo = smu_logo
    }
    else if(campus_name.toLowerCase() === 'uf') {
        app_logo = uf_logo
    }

    // --- END: Campus Logic ----

    return (

        <div className='register_container login'>
            <div className='register_content_container login'>
                <div className='register_content'>
                    <div style={{boxSizing:'border-box', textAlign:'center'}}>
                        
                        {/* Logo */}
                        <div onClick={() => window.location = '/'} className='register_logo'>
                            <img src={app_logo} />
                        </div>

                        {/* Title */}
                        <div className='register_title login'>
                            Log in
                        </div>

                        {/* Header Description */}
                        <div className='register_title secondary login'>
                            Log in or <a style={{color: 'rgb(27, 149, 224)', textDecoration: 'none'}} href={` /register `}>create a new account</a> to continue
                        </div>

                    </div>

                    <div>

                        {!loginWithPassword ? (
                            
                            /* Input fields */
                            <div className='register_form_inputs'>
                                    
                                {/* Input & Label */}
                                <div className='register_input_container login'>
                                    <label for="email" className='register_input_label login'>
                                    Your email address
                                    </label>
                                    <input 
                                        // className='register_input_element login' 
                                        type="text"
                                        placeholder={`e.g., yourname@email.com`}
                                        className="input"
                                        name="email"
                                        value={email}
                                        // maxlength="30"
                                        onChange={e => onChange(e)}
                                    />
                                </div>
                            </div>
                        ) : (
                            
                            /* Input fields */
                            <div className='register_form_inputs'>
                                    
                                {/* Input & Label */}
                                <div className='register_input_container login'>
                                    <label for="email" className='register_input_label login'>
                                    Your email address
                                    </label>
                                    <input 
                                        // className='register_input_element login' 
                                        type="text"
                                        placeholder={`e.g., yourname@email.com`}
                                        className="input"
                                        name="email"
                                        value={email}
                                        // maxlength="30"
                                        onChange={e => onChange(e)}
                                    />
                                </div>

                                {/* Password*/}
                                <div className='register_input_container login'>
                                    <label for="password" className='register_input_label login'>
                                    Password
                                    </label>
                                    <input 
                                        // className='register_input_element login' 
                                        type="password"
                                        placeholder="Enter your password"
                                        className="input"
                                        name="password"
                                        value={password}
                                        maxlength="30"
                                        onChange={e => onChange(e)}
                                    />
                                </div>
                            </div>
                        )}
            
                    </div>

                    {!loginWithPassword ? (
                        
                        /* Form Actions */
                        <div className='register_btns'>
                            <Button 
                                onClick={handleEmailSignInLink} 
                                style={{width: '100%'}}
                                className="sidebar__tweet black"
                            >
                                Continue with email
                            </Button>
                        </div>
                    ) : (
                        /* Form Actions */
                        <div className='register_btns' style={{display: 'flex', alignItems:'center', flexDirection:'column'}}>
                            {/* <Button 
                                href={` /forgotpassword${email.length > 0 ? `?email=${email}` : ''} `}
                                className="sidebar__tweet ghost"
                                fullWidth
                            >
                                Forgot Password
                            </Button> */}

                            {!account_loading ? (
                                <Button 
                                    onClick={onSubmit} 
                                    className="sidebar__tweet black"
                                    fullWidth
                                >
                                    Log in
                                </Button>
                            ) : (
                                <Button 
                                    style={{width: '100%'}}
                                    className="sidebar__tweet black"
                                    disabled={true}
                                >
                                    processing...
                                </Button>
                            )}
        
                        </div>
                    )}

                    <p style={{fontSize: '12px', color: '#808080', fontWeight: 600, marginTop: '1rem', textAlign: 'center'}}>
                        --Or continue with--
                    </p>

                    <div style={{height: '10px'}}></div>

                    <GoogleBtn />

                    <div style={{height: '10px'}}></div>
                    
                    {!loginWithPassword ? (
                        /* Form footer - small text */
                        <div className='register_redirect'>
                            <div>
                            Having issues?
                            </div>
                            <div>
                                <a href={` /login?method=password${email.length > 0 ? `&email=${email}` : ''} `} style={{color:'#3385ff', fontWeight:'bold', cursor: 'pointer'}}>
                                Log in with a password instead
                                </a>
                            </div>
                        </div>
                    ) : (
                        <Fragment>
                            {/* Form footer - small text */}
                            <div className='register_redirect'>
                                <div>
                                    <div>
                                        Having Issues?
                                    </div>
                                    <div style={{marginLeft: '5px'}}>
                                        {/* <a href={` /login?method=noPassword${email.length > 0 ? `&email=${email}` : ''} `} style={{color:'#3385ff', fontWeight:'bold', cursor: 'pointer'}}>
                                        Continue without password
                                        </a> */}

                                        <a href='/settings/help' style={{color:'#3385ff', fontWeight:'bold', cursor: 'pointer'}}>
                                            Contact us
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                            <div style={{height: '10px'}}></div>
                            
                            <div className='register_redirect'>
                                <div>
                                    <div>
                                        New User?
                                    </div>
                                    <div style={{marginLeft: '5px'}}>
                                        <a href='/register' style={{color:'#3385ff', fontWeight:'bold', cursor: 'pointer'}}>
                                            Sign Up
                                        </a>
                                    </div>
                                </div>
                                
                            </div>
                        </Fragment>
                    )}
                    
                </div>
            </div>

            {/* Page footer - fixed to bottom of screen */}
            <div className='register_footer'>
                <hr style={{height:'1px', background:'rgb(232, 233, 235)'}} />
                <div className='register_footer_nav'>
                    <a href='/settings/terms'>
                    Read our privacy policy
                    </a>
                    <a href='/settings/help'>
                    Contact support
                    </a>
                </div>
            </div>
        </div>
        
        // <ModalPage>
        //     {/* <Alert /> */}

        //     <div style={{margin: '15px 10px'}} onClick={goBack} className="form__backBtn">
        //         <ArrowBackIcon />
        //     </div>
                
        //     <Logo />

        //     <div>
        //         <h2>Log in with your email</h2>
        //         <p>Enter your email to continue</p>
        //     </div>
        //     <div className="modal__inputs">
        //         <input
        //             type="text"
        //             placeholder="Email"
        //             className="input"
        //             name="email"
        //             value={email}
        //             onChange={e => onChange(e)}
        //         />
        //         {/* <input
        //             type="password"
        //             placeholder="Password"
        //             className="input"
        //             name="password"
        //             value={password}
        //             onChange={e => onChange(e)}
        //         /> */}
        //     </div>
        //     <div>
        //         <Button 
        //             variant="outlined" 
        //             className="sidebar__tweet" 
        //             fullWidth
        //             onClick={onSubmit}
        //         >
        //             Continue
        //         </Button>
        //     </div>
        //     <div className="auth__footer">
        //         <a href={`/register`}>Don't have one?</a>
        //         <a href={`/register`}>Create an account</a>
        //     </div>
        // </ModalPage>
    )
}

// Prop types for the Login component
Login.propTypes = {
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
    loginWithEmailAndPassword: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    loginWithEmailAndPassword,
    setAlert,
})(Login);
