import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Link } from 'react-router-dom';
import Div100vh, { use100vh } from 'react-div-100vh'

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

import { socket } from '../socket';

// Actions
import { toggleSideNav, setPage, add_To_Recent_History, goBack, toggleUserModal } from '../actions/navActions';
import { setUsers } from '../actions/authActions';
import { getUserChats, createChat, getChatById, getUnreadChats } from '../actions/chatActions';
import { getUnreadCount } from '../actions/notificationActions';

// Modal
import UserModal from '../components/modal/UserModal';
import ModalContainer from '../components/modal/ModalContainer';

// Components - imported
import ChatBox from '../components/ChatBox/ChatBox';
import Message from '../components/Message/Message';
import { Button } from '@material-ui/core';
import Spinner from '../components/common/Spinner';
import { Avatar } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import ProfileDropdown from '../components/Dropdowns/ProfileDropdown';
import AuthDropdown from '../components/Dropdowns/AuthDropdown';
import Avatar_For_Headers from '../components/common/Avatar_For_Headers';

import UserTable from '../components/Tables/UserTable/UserTable';

// Icons - material UI
import NightsStayOutlinedIcon from '@material-ui/icons/NightsStayOutlined';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Btn_For_Headers from '../components/common/Btn_For_Headers';

const initialState = {
    username: '',
    phone: '',
    email: '',
    password: '',
    password2: ''
};

const Messages = ({ 
    toggleSideNav, 
    toggleUserModal, 
    getUnreadCount,
    getUnreadChats,
    setPage, 
    add_To_Recent_History,
    goBack,
    setUsers, 
    getUserChats, 
    createChat, 
    getChatById, 
    auth, 
    chat, 
    nav: { 
        sideNav, 
        page 
    }, 
    notification, 
    location, 
    match, 
    history 
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Check if user is verified
    const [checkedVerification_schoolEmail, setCheckedVerification_schoolEmail] = useState(false);

    // Socket Linked
    const [socketConnected, setSocketConnected] = useState(false);

    // Input field data
    const [ name, setName ] = useState('');
    const [ room, setRoom ] = useState('');

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);
    const [loweredBadge, setLoweredBadge] = useState(false);

    // Toggle Dropdwon
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [authDropdown, setAuthDropdown] = useState(false);

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    useEffect(() => {
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Set the current page to 'messages'
        setPage('messages');
        add_To_Recent_History(url.pathname);  // current url

        // Fetch chat data if chat ID is provided in the URL params
        if(match.params.id) {
            getChatById(match.params.id)
        }

        let tempName;
        let tempRoom;

        // Set the chat name if authenticated
        if(auth.user) {
            tempName = auth.user.first_name + ' ' + auth.user.last_name;
            setName(tempName);
        }
    
        // Set the room ID if provided in the URL params
        if(match.params.id) {
            tempRoom = match.params.id;
            setRoom(tempRoom);
        }

        // console.log('NAME!!!');
        // console.log(tempName);

        // console.log('ROOM!!!')
        // console.log(match.params.id);
        
        // Fetch user chats
        getUserChats();

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, [auth.user, match.params.id]);

    useEffect(() => {

        // Execute this effect only if it's not a tablet
        if(!isTablet) {
            if(sideNav) {

                // Remove the 'active' class from the feed header element for a better slideNav animation
                document.getElementById('feed-header').classList.remove("active");
                return "feed__header";
            } else {

                // Add the 'active' class to the feed header element after a delay for a better slideNav animation
                setTimeout(() => {
                    document.getElementById('feed-header').classList.add("active");
                }, 700)
            }
        }
    }, [sideNav]);

    useEffect(() => {

        // Fetch unread counts for notifications and chats
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user]);

    useEffect(() => {

        // Update the badge value to total (Notifications + unread Chats)
        setBadgeValue(notification.num_unread + chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])

    // useEffect(() => {
    //     if(chat.chat && badgeValue > 0 && !loweredBadge) {
    //         lowerBadgeValue();
    //     }
    // }, [chat.chat])

    // const lowerBadgeValue = () => {
    //     console.log('LOWER BADGE #');

    //     if(chat.chat !== null) {
    //         const newValue = badgeValue - 1;

    //         console.log(newValue);
            
    //         setBadgeValue(newValue);
    //     }

    //     setLoweredBadge(true);
    // }

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop } = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {
    
                // scrolling up - show top nav
                setScrollDirection("up");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)
    }

    // const viewChat = (chatId) => {
        
    //     if(!socketConnected) {
            
    //         socket.emit('join', { name, chatId }, () => {

    //         });

    //         setSocketConnected(true);
    //     } else {
    //         socket.disconnect()

    //         socket.off();

    //         setSocketConnected(false);

    //         viewChat(name, room);
    //     }

    //     getChatById(chatId);
    // }

    // --- ImageKit (img optimization service) ---

    let user_name;

    if(!auth.loading && auth.isAuthenticated) {
        user_name = `${auth.user.first_name} ${auth.user.last_name && auth.user.last_name}`
    }
    
    let optimized_profile_img_path;

    if(auth.user && auth.user.img) {
   
        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = auth.user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }

    // --- END: ImageKit (img optimization service) ---

    let chatList;

    if(chat.chats === null || chat.loading) {

        // Display a spinner while loading chat data
        chatList = <Spinner />;
    }
    else {
        if(chat.chats.length > 0) {

            // Render the list of chats
            chatList = chat.chats.map(chat => {
                if(chat) {
                    return (
                        <Message
                            key={chat._id}
                            auth={auth}
                            chat={chat}
                            getChatById={getChatById}
                            history={history}
                            text={chat.last_message ? chat.last_message : '...'}
                        />
                    )
                }
            })
        }
        else {

            // Display a message if there are no chats
            chatList = (
                <div className="no-chats">
                    <h1>Send a message, get a message</h1>
                    <p>Direct Messages are private conversations between you and other people.</p>
                    {auth.user && (
                        <Button  
                            onClick={toggleUserModal} 
                            variant="outlined" 
                            className="noChats__btn"
                        >
                                Start a conversation
                        </Button>
                    )}
                </div>
            );
        }
    }

    // Chatbox

    let chatBox;

    if(name === '' || room === '') {

        // Display a message when no chat is selected
        chatBox = (
            <div className="no-chats-container">
                <div className="no-chats">
                    <h1>You don’t have a message selected</h1>
                    <p>Choose one from your existing messages, or start a new one.</p>

                    {auth.user && (
                        <Button  
                            onClick={toggleUserModal} 
                            variant="outlined" 
                            className="noChats__btn"
                        >
                            New Message
                        </Button>
                    )}
                </div>
            </div>
        );
    } else {
        if(chat.loading || !auth.user) {

            // Display a spinner while loading chat data or user is not authenticated
            chatBox = <Spinner />;
        }
        else {
            if(chat.chat === null) {

                // Set the current page to 'messages'
                if(page !== 'messages') setPage('messages');
                
                // Display a message when the selected chat does not exist
                chatBox = (
                    <Fragment>
                        <div className="chatBox" style={{height:'100vh'}}>

                            {/* ChatBox Header */}
                            <div className="chatBox__header not_found">

                                {/* Render back button for tablets */}
                                <div onClick={goBack} className="chatBox__btnContainer">
                                    <ArrowBackIcon />

                                    {/* Render badge value if mobile */}
                                    {badgeValue > 0 && (
                                        <span className="feed-header-badge defaultBadge">
                                            {badgeValue}
                                        </span>
                                    )}
                                </div>

                                <h2>
                                    Messages
                                </h2>
                                
                                <div style={{right:'20px', position:'absolute', display: 'flex'}}>
                                    {/* <div className="post__avatar layoutHeader" style={{ color: 'rgb(83, 100, 113)', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px', marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>
                                        <NightsStayOutlinedIcon />
                                    </div> */}

                                    {/* --- Avatar Icon --- */}
                                        <Avatar_For_Headers />
                                    {/* --- END: Avatar Icon --- */}
                                </div>
                            </div>

                            <div className="no-chats-container">
                                <div className="no-chats">
                                    <h1>This conversation doesn’t exist</h1>
                                    <p>Something seems to have gone wrong...</p>

                                    {auth.user && (
                                        <Button  
                                            onClick={toggleUserModal} 
                                            variant="outlined" 
                                            className="noChats__btn"
                                        >
                                            New Message
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Fragment>
                );
            } else {

                // Render the chat box with messages and input field
                chatBox =  (
                    <ChatBox
                        match={match}
                        auth={auth}
                        chat={chat}
                        name={name}
                        room={room}
                        badgeValue={badgeValue}
                        history={history}
                    />
                );
            }
        }
    }

    const handleMixpanel = () => {

        // Initialize Mixpanel with the provided Mixpanel ID
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

        // Identify the current user by their ID
        mixpanel.identify(auth.user._id);

        // Track the "Messages Page View" event
        mixpanel.track("Messages Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && auth.user !== null) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    // Check if user's school email is verified. If no redirect to school email page
    if(auth.user && !auth.user.school_verified && !checkedVerification_schoolEmail) {
        window.location.href = '/settings/account/school_email';

        setCheckedVerification_schoolEmail(true);
    }

    return (
        <Fragment>
            <div className="inbox__container">

                {/* Check if it's not a tablet - desktop only */}
                {!isTablet && (
                    <div onScroll={handleScroll} className="feed">

                        {/* Header */}
                        <div className="feed__header" id="feed-header">

                            {/* Title and toggle button for side navigation */}
                            <h2 style={{display:'flex', alignItems: 'center'}}>

                                <div style={{display:'flex', alignItems: 'center'}}>
                                    {isMobile && (
                                        <Fragment>
                                            <div onClick={goBack} className="profile__btnContainer">
                                                <ArrowBackIcon />
                                            </div>

                                            {badgeValue > 0 && (
                                                <span className="feed-header-badge home">
                                                    {badgeValue}
                                                </span>
                                            )}
                                        </Fragment>
                                    )}

                                    Messages
                                </div>
                                
                                <div style={{right:'20px', position:'absolute', display: 'flex'}}>
                                    
                                    {/* Button for composing a new message */}
                                    {auth.user && (
                                        <Btn_For_Headers handleClick={toggleUserModal}>
                                            <AddIcon />
                                        </Btn_For_Headers>
                                    )}
                                    
                                    {/* <Btn_For_Headers>
                                        <NightsStayOutlinedIcon />
                                    </Btn_For_Headers> */}

                                    {/* --- Avatar Icon --- */}
                                    <Avatar_For_Headers />
                                    {/* --- END: Avatar Icon --- */}
                                </div>
                            </h2>
                        </div>

                        {/* List of chat messages */}
                        {chatList}

                        {/* Add space to the bottom of page */}
                        <div className="no-rides" />
                        
                    </div>
                )}
                
                {chatBox}
                
            </div>
        </Fragment>
    )
}

Messages.propTypes = {
    toggleSideNav: PropTypes.func.isRequired,
    toggleUserModal: PropTypes.func.isRequired,
    getUnreadCount: PropTypes.func.isRequired,
    getUnreadChats: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    setUsers: PropTypes.func.isRequired,
    getUserChats: PropTypes.func.isRequired,
    createChat: PropTypes.func.isRequired,
    getChatById: PropTypes.func.isRequired,
    nav: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    nav: state.nav,
    auth: state.auth,
    chat: state.chat,
    notification: state.notification
});

export default connect(
    mapStateToProps, { 

        // Connecting actions from redux to the component
        toggleSideNav, 
        toggleUserModal, 
        getUnreadCount,
        getUnreadChats,
        setPage, 
        add_To_Recent_History,
        goBack,
        setUsers, 
        getUserChats, 
        createChat, 
        getChatById 
    }
)(Messages);
