import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// ALGOLIA SEARCH
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

// Redux
import { connect } from 'react-redux';

// Actions
import { setSearchPosts, set_NoMoreProducts, set_LastPageDoc, set_ShowBottomSpinner, set_NoMorePosts } from '../../actions/postActions';
import { toggleSideNav, setPage, add_To_Recent_History, goBack, toggleAuthModal } from '../../actions/navActions';
import { setAlert } from '../../actions/alertActions';
import { getPopularSearches } from '../../actions/searchActions';

// Layout Wrapper
import SearchLayout from '../../components/layout/SearchLayout';

// Modals
import ModalContainer from '../../components/modal/ModalContainer';
import DefaultModal from '../../components/modal/DefaultModal';

// components
import Spinner from '../../components/common/Spinner';
import TweetBox from '../../components/TweetBox/TweetBox';
import Post from '../../components/Post/Post';
import GettingStarted from '../../components/common/GettingStarted';
import HowItWorks from '../../components/common/HowItWorks';
import Image_Block from '../MediaPage/Image_Block';
import DefaultAvatar from 'react-avatar';
import { Avatar, Button } from '@material-ui/core';
import People_Block from './People_Block';
import Empty_Card from '../MediaPage/Empty_Card';
import Avatar_For_Headers from '../../components/common/Avatar_For_Headers';

// Icons
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import SignalCellularAltOutlinedIcon from '@material-ui/icons/SignalCellularAltOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import uf_logo from '../../utils/imgs/uf_logo.png'

const School_SearchPage = ({ 
    setSearchPosts,
    toggleSideNav, 
    setPage, 
    add_To_Recent_History,
    goBack,
    toggleAuthModal,
    setAlert, 
    getPopularSearches,
    set_ShowBottomSpinner,
    set_LastPageDoc,
    set_NoMorePosts,
    auth, 
    post: {
        posts,
        no_more_posts,
        show_bottom_spinner,
        last_page_doc,
        loading
    },
    nav: { 
        sideNav, 
        page, 
        postModal 
    }, 
    search: {
        popular_searches,
        popular_loading,
    }
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Modals
    const [show_Modal_Selected_School, set_Show_Modal_Selected_School] = useState(false);
    const [selected_School, set_Selected_School] = useState(null);

    // Get the current URL and extract the "filter" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // -- Toggle Modal for selected school --

    useEffect(() => {

        if(selected_School) set_Show_Modal_Selected_School(true);
    }, [selected_School]);

    const toggleModal_Selected_School = () => {
        if(show_Modal_Selected_School) {
            set_Selected_School(null);
        }

        set_Show_Modal_Selected_School(!show_Modal_Selected_School);
    }

    const handle_Join_Campus_Click = () => {
        if(auth.user) {
            if(selected_School) {
                window.location.href = `/settings/account/school_email?campus=${selected_School._id}`
            }
        } else {
            toggleModal_Selected_School();
            toggleAuthModal('join campus');
        }



    };

    const continueMessage = () => {
        
    }

    // -- END: Toggle Modal for selected school --

    // -- Algoia Search --
    
    const [searchCategoriesClient, setSearchCategoriesClient] = useState(null);

    useEffect(() => {

        setSearchCategoriesClient(algoliasearch('H8YQ3RB3ZI', 'b797e6858550b93ae143de5b4941f4e9'));
    }, []);

    // const categoriesIndex = searchCategoriesClient.initIndex('categories');

    // -- END: Algoia Search --

    // -- Algolia Components --

    const Hit = ({hit}) => {
        console.log('THIS IS A HIT');
        console.log(hit);
        
        return (
            <div onClick={() => set_Selected_School({_id: hit.objectID, ...hit})} className="search-dropdown-item" key={hit.objectID}>
                <div>
                    <div className='search_dropdown_item_icon has_circular_border'>
                        <RoomOutlinedIcon />
                    </div>
                </div>
                <div className='search_dropdown_item_option'>
                    <div className='generated_result'>
                        <span>
                            {hit.location_details.city} &#x2022; {hit.location_details.state} &#x2022; {hit.location_details.country}
                        </span>
                        <span>
                            {hit.name}
                        </span>
                    </div>
                    <div className='search_stats'>
            
                    </div>
                </div>
            </div>
            // <Modal_CollectionRow
            //     detailCollection={{
            //         _id: hit.objectID,
            //         ...hit
            //     }}
            //     selected_collections={collection.selected_collections}
            // />
        )
    }

    // -- END: Algolia Components --
    
    useEffect(() => {
        setPage('search schools');
        add_To_Recent_History(url.pathname);  // current url

    }, []);

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };


    // Function to handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Search Schools Page View");
    }

    // Trigger Mixpanel analytics on initial render in production mode
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    // Redirct from School Modal
    const handle_Campus_Redirect = (campus_url) => {
        window.open(campus_url, '_blank');
        toggleModal_Selected_School();
    }

    return (

        <Fragment>
            <div className="feed__container">
                <div className="feed">

                    {/**Header */}
                    <div className="feed__header" id="feed-header">
                            
                        {/*
                            Render the header content based on the current page and filter
                            Display a menu icon for mobile devices and handle slide menu if clicked
                            Display the appropriate header text based on the filter value
                            Display a badge with the badgeValue if it's greater than 0 and it's a mobile device
                        */}

                        <div className="shopFeed__search">

                            {/* Shopping Cart Icon */}
                            <div onClick={goBack} className="shopFeed__backBtn">
                                <div>
                                    <ArrowBackIcon />
                                </div>
                            </div>

                            <div className="settings__headerText">
                                <h3>
                                    Find Your School
                                </h3>
                                
                            </div>

                            {/* --- Avatar Icon --- */}
                            <div className="shopFeed__cog">
                                <Avatar_For_Headers />
                            </div>
                            {/* --- END: Avatar Icon --- */}
                        </div>          
                    </div>

                    <div className="settings__body custom_algolia_search_components no_flex fullWidth">
                        {searchCategoriesClient && (
                            <InstantSearch searchClient={searchCategoriesClient} indexName='campuses'>
                                

                                {/* Input */}
                                <div style={{width: '100%', boxSizing:'border-box', padding:'10px', display:'flex', alignItems: 'center', justifyContent:'center', borderBottom:'1px solid #e6ecf0'}}>
                                    
                                    <SearchBox placeholder="Search schools" searchAsYouType={true}></SearchBox>

                                    <div className="widgets__searchIcon has_circular_border" style={{display: 'flex', marginLeft: '10px', justifyContent: 'center', alignItems: 'center'}}>
                                        <SearchIcon />
                                    </div>
                                    
                                </div>

                                {/* Results */}
                                <Hits hitComponent={Hit}></Hits>
                                
                            </InstantSearch>
                        )}

            
                    </div>

                    <div className="no-rides"></div>
                </div>

            </div>

            {/* How It Works Modal */}
            <ModalContainer show={show_Modal_Selected_School} onClose={toggleModal_Selected_School}>
                <DefaultModal>

                    {/* Close Button */}
                    <div onClick={toggleModal_Selected_School} className="closeBtn__container start transparent no_header">
                        <div className="closeBtn">
                            <CloseOutlinedIcon />
                        </div>
                    </div>

                    <div style={{height: '35px'}}></div>

                    {selected_School ? (
                        <Fragment>
                            {selected_School.campus_logo && (
                                <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img style={{width: '35%'}} src={selected_School.campus_logo} />
                                </div>
                            )}

                            <div style={{flexDirection: 'column'}}>
                                <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '24px'}}>
                                    {selected_School.name}
                                </div>
                                {/* <HowItWorks /> */}
                            </div>

                            <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
                                {/* Sign Up Button */}

                                {auth.user?.school_verified && (
                                    <Fragment>
                                        {auth.user?.campus_id === selected_School._id ? (
                                            <Button 
                                                variant="outlined" 
                                                className="sidebar__tweet"
                                                onClick={() => handle_Campus_Redirect(selected_School.url)}
                                            >
                                                View Your Campus
                                            </Button>
                                        ) : (
                                            <Button 
                                                variant="outlined" 
                                                className="sidebar__tweet black"
                                                onClick={() => handle_Campus_Redirect(selected_School.url)}
                                            >
                                                Visit As Guest
                                            </Button>
                                        )}
                                    </Fragment>
                                )}

                                {!auth.user?.school_verified && (
                                    <Button 
                                        variant="outlined" 
                                        className="sidebar__tweet black"
                                        onClick={handle_Join_Campus_Click}
                                    >
                                        Join Campus
                                    </Button>
                                )}

                            </div>
                        </Fragment>
                    ) : (
                        <div>Something went wrong</div>
                    )}
                    
                </DefaultModal>
            </ModalContainer>
        </Fragment>
    )
}

School_SearchPage.propTypes = {
    setSearchPosts: PropTypes.func.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired, // Function prop to set the current page in the navigation
    add_To_Recent_History: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    getPopularSearches: PropTypes.func.isRequired,
    set_ShowBottomSpinner: PropTypes.func.isRequired,
    set_LastPageDoc: PropTypes.func.isRequired,
    set_NoMorePosts: PropTypes.func.isRequired,
    post: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired, // Object prop representing the authentication state of the user
    search: PropTypes.object.isRequired,
    toggleAuthModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    post: state.post,
    nav: state.nav,
    auth: state.auth,
    search: state.search
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    setSearchPosts,
    toggleSideNav, 
    setPage, 
    add_To_Recent_History,
    goBack,
    setAlert,
    getPopularSearches,
    set_ShowBottomSpinner,
    set_LastPageDoc,
    set_NoMorePosts,
    toggleAuthModal
})(School_SearchPage);
