import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// ALGOLIA SEARCH
import algoliasearch from 'algoliasearch/lite';

// Redux
import { connect } from 'react-redux';

// Actions
import { setSearchPosts, set_NoMoreProducts, set_LastPageDoc, set_ShowBottomSpinner, set_NoMorePosts } from '../../actions/postActions';
import { toggleSideNav, setPage, add_To_Recent_History } from '../../actions/navActions';
import { setAlert } from '../../actions/alertActions';
import { getPopularSearches } from '../../actions/searchActions';

// Layout Wrapper
import SearchLayout from '../../components/layout/SearchLayout';

// components
import Spinner from '../../components/common/Spinner';
import TweetBox from '../../components/TweetBox/TweetBox';
import Post from '../../components/Post/Post';
import GettingStarted from '../../components/common/GettingStarted';
import HowItWorks from '../../components/common/HowItWorks';
import Image_Block from '../MediaPage/Image_Block';
import DefaultAvatar from 'react-avatar';
import { Avatar, Button } from '@material-ui/core';
import People_Block from './People_Block';
import Empty_Card from '../MediaPage/Empty_Card';

// Icons
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import SignalCellularAltOutlinedIcon from '@material-ui/icons/SignalCellularAltOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const SearchPage = ({ 
    setSearchPosts,
    toggleSideNav, 
    setPage, 
    add_To_Recent_History,
    setAlert, 
    getPopularSearches,
    set_ShowBottomSpinner,
    set_LastPageDoc,
    set_NoMorePosts,
    auth, 
    post: {
        posts,
        no_more_posts,
        show_bottom_spinner,
        last_page_doc,
        loading
    },
    nav: { 
        sideNav, 
        page, 
        postModal 
    }, 
    search: {
        popular_searches,
        popular_loading,
    }
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Check if user is verified
    const [checkedVerification_schoolEmail, setCheckedVerification_schoolEmail] = useState(false);

    // Posts
    const [postList, setPostList] = useState([]);
    const [gotPosts, setGotPosts] = useState(false);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Temporarily disable to load more function to avoid duplicate fetch requests
    const [canLoadMore, setCanLoadMore] = useState(true);

    // Get the current URL and extract the "filter" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("q");
    const display_filter = url.searchParams.get("show");

    const SPACE_HEIGHT = 250; // Hieght of className 'no-rides' which is a spacer to make sure user can always scroll down


    // Firebase collection ref
    const postsCollectionRef = collection(db, "posts");

    // Algolia search
    const searchClient = algoliasearch('H8YQ3RB3ZI', 'b797e6858550b93ae143de5b4941f4e9'); // change the secret key (2nd) to specific api key
    const searchIndex = searchClient.initIndex('posts');

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;
    
    useEffect(() => {
        setPage('search');
        add_To_Recent_History(url.pathname);  // current url
        getPopularSearches();

    }, []);

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {

        renderPostList();

    }, [posts, loading, canLoadMore]);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {
    
                // scrolling up - show top nav
                setScrollDirection("up");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

        // Check if the user has scrolled to the bottom of the content and conditions for loading more posts are met
        if (newScrollY >= scrollHeight - (SPACE_HEIGHT+1) && !show_bottom_spinner && !no_more_posts && filter !== null && popular_searches.length > 0 && posts.length > 0 && canLoadMore && display_filter !== 'people') {
            console.log('scrolling');
            loadMore(last_page_doc);
        }
    }

    // Function to load more paginated posts
    const loadMore = async (lastPage_doc) => {

        try {
            console.log("last", lastPage_doc);

            // Temporarily disable to load more function to avoid duplicate fetch requests
            setCanLoadMore(false);

            // Show spinner
            set_ShowBottomSpinner(true);

            const results = await searchIndex.search(filter, {
                page: lastPage_doc,
                hitsPerPage: 10
            });

            console.log('SEARCH RESULT');
            console.log(results);

            // setPosts(results.hits.map((doc) => ({...doc, _id: doc.objectID})));

            // Fetch initial posts
        
            console.log('UPDATING POSTS...');
            const tempPostList = results.hits.map((doc) => ({...doc, _id: doc.objectID}));

            if(tempPostList.length < 2) {
                set_NoMorePosts(true);
            }

            // Recursively fetch comments of comments
            const fetchCommentsOfComments = async (comments) => {
                for (let i = 0; i < comments.length; i++) {
                const comment = comments[i];
                
                // Create a query to retrieve comments where the post_commented_on field matches the comment ID, ordered by creation date in descending order
                const commentOfCommentQuery = query(postsCollectionRef, where("post_commented_on", "==", comment._id), orderBy('createdAt', 'asc'));
                
                // Execute the query and retrieve the query snapshot
                const commentOfCommentSnapshot = await getDocs(commentOfCommentQuery);
                
                // Map through the document snapshots in the query snapshot and extract the data and ID for each comment of comment
                const commentsOfComments = commentOfCommentSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
                
                // Append the comments of comments to the current comment
                comment.commentsOfComments = commentsOfComments;
                
                // Recursively fetch comments of comments for the current comment
                await fetchCommentsOfComments(commentsOfComments);
                }
            };
            
            // Fetch comments of comments for the initial set of comments
            await fetchCommentsOfComments(tempPostList);

            setSearchPosts(tempPostList);

            // Get the last visible document for the next load
            set_LastPageDoc(lastPage_doc + 1);
    
            // Hide the loading spinner
            set_ShowBottomSpinner(false);

            // Re-enable the load more function after 1 second
            setTimeout(() => {
                setCanLoadMore(true);
            }, 1000);


        } catch (error) {
            console.log('Error: Loading more search results')
            console.log(error);
        }
    }

    // END - Infinite Scroll

    const renderPostList = async () => {
        setPostList([]);

        if((posts === null && loading) || loading || !gotPosts) {
            setPostList([(
                <div className="no-rides">
                    <Spinner />
                </div>
            )])
        }
        else { 
            if(posts.length > 0) {
                posts.map(post_doc => {
                    if (display_filter === 'media') {
                        setPostList(postList => [...postList, (
                            <Image_Block key={post_doc._id} detailPost={post_doc} />
                        )])
                    } else {
    
                        const renderPostRecursive = (postComments, parentPost) => {
                            
                            const lastComment = postComments[postComments.length - 1]; // Get the last comment
    
                            return (
                                <Fragment>
                                    <Post key={lastComment._id} postDoc={lastComment} parentPost={parentPost} />
    
                                    {/* Recursive call to render comments of comments */}
                                    {lastComment.commentsOfComments && lastComment.commentsOfComments.length > 0 && (
                                        <div className="comments-of-comments">
                                        {renderPostRecursive(lastComment.commentsOfComments, lastComment)}
                                        </div>
                                    )}
                                </Fragment>
                            );
    
                        };
    
                        if(post_doc.commentsOfComments && post_doc.commentsOfComments.length > 0) {
                            setPostList(postList => [...postList, (
                                <div className='post_container'>
                                    <Post key={post_doc._id} postDoc={post_doc} />
    
                                    {renderPostRecursive(post_doc.commentsOfComments, post_doc)}
                                </div>
                            )])
                        } else {
                            setPostList(postList => [...postList, (
                                <Post key={post_doc._id} postDoc={post_doc} parentPost={post_doc.post_commented_on && true} />
                            )])
                        }
    
                    }
                }) 
            } else if(filter !== null) {
                // Display a message when there are no search posts
                setPostList(postList => [...postList, (
                    <div className="no-results" style={{marginTop:'80px', minHeight: '200px'}}>
                        <div className='search_imgContainer'>
                            <img style={{maxWidth:'55%'}} src="https://abs.twimg.com/responsive-web/client-web/rubber-chicken-800x400.v1.3a2d1b19.png" />
                        </div>
                        
                        <h1>No results for <br/>"{filter}"</h1>
                        <h2>Try searching for something else</h2>
                    </div>
                )])
            } else {
                // Display a default message 
                setPostList(postList => [...postList, (
                    <div className="no-rides" style={{marginTop:'80px', minHeight: '200px'}}>
                        <h1>Start typing to search…</h1>
                        <img style={{maxWidth:'70%'}} src="https://cdn.hashnode.com/res/hashnode/image/upload/v1634545140037/TJzLog6cK.png?w=800&h=602&auto=compress&auto=compress,format&format=webp" />
                    </div>
                )])
            }
        } 

        setGotPosts(true);
    }

    // --- Search People ---

    let userList;

    if(display_filter === 'people') {

        // Check if users are null or not yet fetched
        if(auth.users === null || auth.loading) {
            userList = <Spinner />;
        }
        else {
            if(auth.users.length > 0) {

                // Iterate through the users array and render each post component
                userList = auth.users.map(user_obj => {

                    return (
                        <People_Block hit={user_obj} />
                    )
                    

                })
            }
            else if(filter !== null) {
                // Display a message when there are no search auth.users
                userList = (
                    <div className="no-results" style={{marginTop:'80px', minHeight: '200px'}}>
                        <div className='search_imgContainer'>
                            <img style={{maxWidth:'55%'}} src="https://abs.twimg.com/responsive-web/client-web/rubber-chicken-800x400.v1.3a2d1b19.png" />
                        </div>
                        
                        <h1>No results for <br/>"{filter}"</h1>
                        <h2>Try searching for something else</h2>
                    </div>
                );
            } else {
                // Display a default message 
                userList = (
                    <div className="no-rides" style={{marginTop:'80px', minHeight: '200px'}}>
                        <h1>Start typing to search…</h1>
                        <img style={{maxWidth:'70%'}} src="https://cdn.hashnode.com/res/hashnode/image/upload/v1634545140037/TJzLog6cK.png?w=800&h=602&auto=compress&auto=compress,format&format=webp" />
                    </div>
                );
            }
        }
    }
    
    // --- END: Search People ---

    let popularSearch_list;

    // Check if posts are null or not yet fetched
    if(popular_searches === null) {
        popularSearch_list = <Spinner />;
    }
    else {
        if(popular_searches.length > 0) {

            // Iterate through the posts array and render each post component
            popularSearch_list = popular_searches.map(popular_search => {
                if(popular_search) {
                    return (
                        <div onClick={() => window.location = `/search?q=${popular_search.text}`} className="search-dropdown-item" key={popular_search._id}>
                            <div className='search_dropdown_item_icon'>
                                <SearchIcon />
                            </div>
                            <div className='search_dropdown_item_option'>
                                <div className='generated_result'>
                                    <span>
                                        
                                    </span>
                                    <span>
                                        {popular_search.text}
                                    </span>
                                </div>
                                <div className='search_stats'>
                                    {popular_search.total_views}
                                    <SignalCellularAltOutlinedIcon />
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        } else {
            // Display a default message 
            popularSearch_list = (
                <div className="no-rides" style={{marginTop:'80px', minHeight: '200px'}}>
                    <h1>Start typing to search…</h1>
                    <img style={{maxWidth:'70%'}} src="https://cdn.hashnode.com/res/hashnode/image/upload/v1634545140037/TJzLog6cK.png?w=800&h=602&auto=compress&auto=compress,format&format=webp" />
                </div>
            );
        }
    }


    // Function to handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Search Page View");
    }

    // Trigger Mixpanel analytics on initial render in production mode
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    // Check if user's school email is verified. If no redirect to school email page
    if(auth.user && !auth.user.school_verified && !checkedVerification_schoolEmail) {
        window.location.href = '/settings/account/school_email';

        setCheckedVerification_schoolEmail(true);
    }

    return (
        <Fragment>
            <SearchLayout lastPageDoc={last_page_doc} setLastPageDoc={set_LastPageDoc} popularSearch_list={popularSearch_list} popular_searches={popular_searches} handleScroll={handleScroll} sideNav={sideNav} handleSlideMenu={toggleSideNav} page="home">

                {/* Render AddIcon for creating a post on tablet devices */}
                {/* {!auth.loading && auth.user ? (
                    isTablet && (
                        <Link to={`/create/post?goBack=${page}`} id="fixed-button" className="show">
                            <AddIcon />
                        </Link>
                    )
                ) : null} */}

                {filter !== null && (
                    <div className="feed_headerTabs">
                        <div className={`${(display_filter === null || display_filter === undefined || display_filter === 'latest') && 'active'}`} onClick={() => window.location = `${window.location.pathname}?q=${filter}`}>
                            <h3>Latest</h3>
                            <div className="block__underline"></div>
                        </div>
                        <div className={`${(display_filter && display_filter === 'media') && 'active'}`} onClick={() => window.location = `${window.location.pathname}?q=${filter}&show=media`}>
                            <h3>Media</h3>
                            <div className="block__underline"></div>
                        </div>
                        <div className={`${(display_filter && display_filter === 'people') && 'active'}`} onClick={() => window.location = `${window.location.pathname}?q=${filter}&show=people`}>
                            <h3>People</h3>
                            <div className="block__underline"></div>
                        </div>
                    </div>
                )}
                
                
                {/* Render the postList */}
                {filter !== null && popular_searches.length > 0 ? (
                    <Fragment>
                        {display_filter === 'media' && posts?.length > 0 && (
                            <div style={{width: '100%', height: 'fit-content', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridAutoRows: 'min-content', borderBottom: '1px solid #ddd'}}>
                                
                                {postList}

                                {(!no_more_posts && posts.length !== 0) && (<Empty_Card last_page_doc={last_page_doc} page={"gallery"} loadMore={loadMore} setCanLoadMore={setCanLoadMore} canLoadMore={canLoadMore} />)}
                            </div>
                        )}

                        {display_filter === 'people' && userList}

                        {(display_filter !== 'media' && display_filter !== 'people') && postList}

                        {(display_filter === 'media' && !(posts?.length > 0)) && postList}

                        {/* Add space to the bottom of the page b/c of auth alert  */}
                        <div style={{height: '200px'}}></div>
                    </Fragment>
                ) : (
                    <Fragment>
                        {popular_searches.length > 0 && (
                            <div className='menu-title' style={{display: 'flex', justifyContent:'space-between', alignItems: 'center', padding: '12px 16px'}}>
                                <div style={{lineHeight:'24px', fontSize:'20px', fontWeight:700, wordWrap:'break-word', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
                                    <span>Popular searches</span>
                                </div>
                            </div>
                        )}

                        {popularSearch_list}
                    </Fragment>
                )}

                {/* Render a spinner at the bottom if there are more posts to load */}
                {show_bottom_spinner && !no_more_posts && <Spinner />}
                {/* <button onClick={loadMore}>More Posts</button> */}
                
                {/* Display a message when there are no more posts */}
                <div className="no-rides">{(no_more_posts && posts.length !== 0) && "You've reached the end."}</div>
            </SearchLayout>
        </Fragment>
    )
}

SearchPage.propTypes = {
    setSearchPosts: PropTypes.func.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired, // Function prop to set the current page in the navigation
    add_To_Recent_History: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    getPopularSearches: PropTypes.func.isRequired,
    set_ShowBottomSpinner: PropTypes.func.isRequired,
    set_LastPageDoc: PropTypes.func.isRequired,
    set_NoMorePosts: PropTypes.func.isRequired,
    post: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired, // Object prop representing the authentication state of the user
    search: PropTypes.object.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    post: state.post,
    nav: state.nav,
    auth: state.auth,
    search: state.search
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    setSearchPosts,
    toggleSideNav, 
    setPage, 
    add_To_Recent_History,
    setAlert,
    getPopularSearches,
    set_ShowBottomSpinner,
    set_LastPageDoc,
    set_NoMorePosts,
})(SearchPage);
