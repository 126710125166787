import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

// Firebase
import { auth } from '../../config/firebase-config';
import { sendPasswordResetEmail } from "firebase/auth";

// Redux
import { connect } from 'react-redux';

// Actions
import { passwordReset } from '../../actions/authActions';
import { setAlert } from '../../actions/alertActions';

// Layout Wrapper
import ModalPage from '../../components/layout/ModalPage';

// Components - imported
import { Button } from '@material-ui/core';
import Alert from '../../components/common/Alert';

// Icons -imported
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import TwitterIcon from '@material-ui/icons/Twitter';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import campus_logo from '../../utils/imgs/campusbuysell_logo.png';
import ou_logo from '../../utils/imgs/ou_logo.png';
import ut_logo from '../../utils/imgs/ut_logo.png';
import smu_logo from '../../utils/imgs/smu_logo.png';
import uf_logo from '../../utils/imgs/uf_logo.png';

const Forgot_Password_Body = ({
    auth: { 
        isAuthenticated, 
        user 
    },
    nav: {
        campus_name,
        campus_ext
    },
    passwordReset,
    setAlert,
    history
}) => {

    // State for tracking if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);
    
    // State for tracking the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // State for form data
    const [email, setEmail] = useState('');

    // State to toggle email code inputs
    const [sentEmailLink, setSentEmailLink] = useState(false);

    // Get the URL filter and check if there is a "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const url_Email = url.searchParams.get("email");

    useEffect(() => {
        
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {
        if(url_Email) {
            console.log('URL EMAIL EXISTS')
            console.log(url_Email)
            setEmail(url_Email);
        } 

    }, [url_Email]);

    // Function to handle window size change
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Function to go back in history
    const goBack = (e) => {
        history.goBack();
    }

    // Check if the device is mobile or tablet
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Function to handle input change
    const onChange = e => setEmail(e.target.value);

    // Function to validate email format
    const validateEmail = (input) => {

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      
        if (input.match(validRegex)) {
      
          return true;
      
        } else {
      
          return false;
      
        }
    }

    

    const handleEmailLink = async (e) => {
        e.preventDefault();

        let callbackURL = `${process.env.REACT_APP_DOMAIN}/login?method=password`;

        // Configuration for the action code settings
        const actionCodeSettings = {

            // URL you want to redirect back to.
            // MUST BE in the authorized domains list in the Firebase Console.
            url: callbackURL,

            // This must be true. Indicates that the email action code will be handled in the app
            handleCodeInApp: true
        };
        
        if(email === '') {
            setAlert('Email is required.', 'danger');
        } else if (!validateEmail(email)) {
            setAlert('Invalid email address!', 'danger');
        } else {

            // Send email reset link & show next disclaimer / login page link
            try {

                console.log('SENDING EMAIL')
                await sendPasswordResetEmail(auth, email, actionCodeSettings)
                setSentEmailLink(true)
                setEmail('');
                
            } catch (error) {    
                if (error.code === 'auth/user-not-found') {
                  window.alert('User not found, try again!')
                  setEmail('');
                }
            }
        }
    }

    // Function to handle Mixpanel event tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Forgot Password Page View");
    }

    // Send Mixpanel event only in production and if not already sent
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    // --- Campus Logic ----

    let app_logo;

    if(campus_name.toLowerCase() === 'campus') {
            
        app_logo = campus_logo
    }
    else if(campus_name.toLowerCase() === 'ou') {
            
        app_logo = ou_logo
    }
    else if(campus_name.toLowerCase() === 'ut') {
        app_logo = ut_logo
    }
    else if(campus_name.toLowerCase() === 'smu') {
        app_logo = smu_logo
    }
    else if(campus_name.toLowerCase() === 'uf') {
        app_logo = uf_logo
    }

    // --- END: Campus Logic ----

    return (

        <div className='register_container login'>
            <div className='register_content_container login'>
                <div className='register_content'>
                    <div style={{boxSizing:'border-box', textAlign:'center'}}>
                        
                        {/* Logo */}
                        <div onClick={() => window.location = '/'} className='register_logo'>
                            <img src={app_logo} />
                        </div>

                        {/* Title */}
                        <div className='register_title login'>
                            Forgot Password
                        </div>

                    </div>
               
                        <div className='register_form login'>
                                
                            {/* Input fields */}
                            <div className='register_form_inputs'>
                                    
                                {!sentEmailLink ? (
                                    /* Input & Label */
                                    <div className='register_input_container login'>
                                        <label for="email" className='register_input_label login'>
                                        Your email address
                                        </label>
                                        <input 
                                            // className='register_input_element login' 
                                            type="text"
                                            placeholder="e.g., yourname@email.com"
                                            className="input"
                                            name="email"
                                            value={email}
                                            // maxlength="30"
                                            onChange={e => onChange(e)}
                                        />
                                    </div>
                                ) : (
                                    <div className='fp_confirmIcon'>
                                        <VerifiedUserIcon />
                                    </div>
                                )}

                                {sentEmailLink && (
                                    <Fragment>
                                        <div className='register_redirect'>
                                            <div>
                                                Check your inbox for the link to reset your password.
                                            </div>
                                            <div style={{marginTop: '2px', color: 'gray'}}>
                                                This may take 1-2 minutes.
                                            </div>
                                            {/* Button Actions */}
                                            <div style={{marginBottom:'16px', boxSizing:'border-box'}}>
                                                <a href="https://outlook.office.com/mail/" style={{margin: '0 10px', cursor:'pointer', color:'#3385ff', textDecoration:'none'}} target="_blank">
                                                    Go to your mail
                                                </a>

                                                {/* {' '} | {' '}

                                                <a href="#" style={{margin: '0 10px', cursor:'pointer', color:'#3385ff', textDecoration:'none'}}>
                                                    Resend Email
                                                </a> */}
                                                
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                
                        </div>
                        {/* Form Actions */}
                        <div className='register_btns'>
                            {!sentEmailLink ? (
                                <div style={{width: '100%'}}>
                                    <button 
                                        onClick={handleEmailLink} 
                                        style={{width: '100%'}}
                                        className={`register_btn login active`}
                                    >
                                        Send reset link
                                    </button>

                                    <button 
                                        onClick={() => window.location = `/login?method=password${email.length > 0 ? `&email=${email}` : ''} `} 
                                        className={`register_btn ghost login`}
                                        style={{width: '100%', color:'#333', border:'1px solid #e6ecf0'}}
                                    >
                                        Go back
                                    </button>
                                </div>
                            ) : (
                                <button 
                                    onClick={() => window.location = `/login?method=password${email.length > 0 ? `&email=${email}` : ''} `}
                                    className={`register_btn ghost login`}
                                    style={{width: '100%', color:'#333', border:'1px solid #e6ecf0'}}
                                >
                                    Go to login
                                </button>
                            )}
                        </div>

                    
                </div>
            </div>

            {/* Page footer - fixed to bottom of screen */}
            <div className='register_footer'>
                <hr style={{height:'1px', background:'rgb(232, 233, 235)'}} />
                <div className='register_footer_nav'>
                    <a href='/settings/terms'>
                    Read our privacy policy
                    </a>
                    <a href='/settings/help'>
                    Contact support
                    </a>
                </div>
            </div>
        </div>
    )
}

// Prop types for the Login component
Forgot_Password_Body.propTypes = {
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    passwordReset: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setAlert,
    passwordReset
})(Forgot_Password_Body);
