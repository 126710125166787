import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { updateStripe, update_payouts_enabled, update_charges_enabled, update_stripe_onboarding_complete } from '../../actions/authActions';
// components - material UI
import { Avatar, Button } from '@material-ui/core';

// icons - material UI
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';

const Payment_Settings = ({ 
    auth: {
        user
    },
    updateStripe,
    update_payouts_enabled, 
    update_charges_enabled, 
    update_stripe_onboarding_complete,
    handleSlideMenu, 
    history,
    goBack
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // On load Check if the user's stripe info is up-to-date
    useEffect(() => {

        if(user.stripe_account_id) {
            fetchStripeAccount();
        }
    }, [user]);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    const createStripeAccount = async (e) => {
        console.log('STARTING AUTH!!!!!');

        const res = await axios.get(`https://intense-beyond-56709-63aadc62ac0b.herokuapp.com/create_stripe_account?first=${user.first_name}?last=${user.last_name}?username=${user.username}?email=${user.email}`);

        console.log('AUTH LINK: ' + res.data.account_id)

        // store the account stripe Id in the user document
        updateStripe(res.data.account_id)

        // window.location.href = res.data.url; 
    }

    const generateAccountLink = async (e) => {
        console.log('STARTING AUTH!!!!!');

        const res = await axios.get(`https://intense-beyond-56709-63aadc62ac0b.herokuapp.com/create_account_link?acct_id=${user.stripe_account_id}`);

        console.log('AUTH LINK: ' + res.data.account_id)

        window.location.href = res.data.url; 
    }

    const fetchStripeAccount = async (e) => {
        console.log('FETCHING ACCOUNT!!!!!');

        const headers = {
            'Authorization': `Bearer sk_test_51NcEk3AXa7dSRqoJR1gk9ZCQeVMawiMBcC2RuFg4vH07f2QiMS7CuiWqrrYe7q3kErwuG5z6JiiZWQx2SCWeJLpG00yC3xDvQh`,
          };

        const res = await axios.get(`https://api.stripe.com/v1/accounts/${user.stripe_account_id}`, { headers });

        console.log('STRIPE ACCT DATA: ' + res.data.payouts_enabled)

        if(res.data.payouts_enabled !== user.payouts_enabled) {
            update_payouts_enabled(res.data.payouts_enabled)
        }

        if(res.data.charges_enabled !== user.charges_enabled) {
            update_charges_enabled(res.data.charges_enabled)
        }

        if(res.data.details_submitted !== user.stripe_onboarding_complete) {
            update_stripe_onboarding_complete(res.data.details_submitted)
        }

        // window.location.href = res.data.url; 
    }

    const viewStripeDashboard = async (e) => {
        console.log('VIEW STRIPE DASHBOARD!!!!!');

        const { data: loginLink_obj } = await axios.post("https://intense-beyond-56709-63aadc62ac0b.herokuapp.com/get_login_link", {
            acctID: user.stripe_account_id
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        console.log('LOGIN LINK: ' + loginLink_obj.url)

        if(isTablet) {
            window.location = loginLink_obj.url;
        } else {
            window.open(
                loginLink_obj.url,
                '_blank' // <- This is what makes it open in a new window.
            );
        }

        
    }

    // Initialize Mixpanel and track settings Help page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings Payment Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <div className="settings">

            {/* Header */}
            <div className="settings__header">
                {isTablet && (

                    // Icon
                    <div onClick={goBack} className="profile__btnContainer">
                        <ArrowBackIcon />
                    </div>
                )}

                {/* Title */}
                <div onClick={handleSlideMenu} className="settings__headerText">
                    <h3>
                        Payments
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            
            {user?.stripe_onboarding_complete ? (
                <div className="settings__body terms">
                    <div className="return-policy">
                        <div className='settings__disclaimer'>
                            <CheckIcon />
                            <h3 >Payouts Enabled</h3>
                        </div>
                        <h5 className='no_margin_bottom'>Stripe Account</h5>
                        <div style={{height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <img style={{height:'100%'}} src="https://media.designrush.com/inspiration_images/135142/conversions/_1511452770_462_stripe-mobile.jpg"/>
                        </div>

                        <div>
                            <p style={{textAlign: 'center'}}>OU Buy / Sell partners with Stripe to transfer earnings to your bank account.</p>

                            <Button onClick={viewStripeDashboard} variant="outlined" className="sidebar__tweet" fullWidth><b>View Earnings</b></Button>
                        </div>
                    
                    </div>
                </div>
            ) : (
                <div className="settings__body terms">
                    <div className="return-policy">
                        <h5 className='no_margin_bottom'>Stripe Account</h5>
                        <div style={{height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <img style={{height:'100%'}} src="https://media.designrush.com/inspiration_images/135142/conversions/_1511452770_462_stripe-mobile.jpg"/>
                        </div>

                        <div>
                            <p style={{textAlign: 'center'}}>OU Buy / Sell partners with Stripe to transfer earnings to your bank account.</p>

                            {user?.stripe_account_id ? (
                                <Button onClick={generateAccountLink} variant="outlined" className="sidebar__tweet" fullWidth><b>Resume Application</b></Button>
                            ) : (
                                <Button onClick={createStripeAccount} variant="outlined" className="sidebar__tweet" fullWidth><b>Set Up Payouts</b></Button>
                            )}
                        </div>
                    
                    </div>
                </div>
        
            )}
        </div>
    )
}

Payment_Settings.propTypes = {
    auth: PropTypes.object.isRequired,
    updateStripe: PropTypes.func.isRequired,
    update_payouts_enabled: PropTypes.func.isRequired, 
    update_charges_enabled: PropTypes.func.isRequired, 
    update_stripe_onboarding_complete: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
})

export default connect(mapStateToProps, { 
    updateStripe,
    update_payouts_enabled, 
    update_charges_enabled, 
    update_stripe_onboarding_complete
})(Payment_Settings);
