import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../config/firebase-config';

// Redux
import { connect } from 'react-redux';

// Actions
import { login, loginWithEmailAndPassword, checkIfUserExists, signInWithGoogle } from '../../actions/authActions';
import { setAlert } from '../../actions/alertActions';


// Layout Wrapper
import ModalPage from '../../components/layout/ModalPage';

// Components - imported
import { Button } from '@material-ui/core';
import Alert from '../../components/common/Alert';
import GoogleBtn from '../../components/common/GoogleBtn';

// Icons -imported
import TwitterIcon from '@material-ui/icons/Twitter';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

import Logo from '../../components/common/Logo';

import campus_logo from '../../utils/imgs/campusbuysell_logo.png';
import ou_logo from '../../utils/imgs/ou_logo.png';
import ut_logo from '../../utils/imgs/ut_logo.png';
import smu_logo from '../../utils/imgs/smu_logo.png';
import uf_logo from '../../utils/imgs/uf_logo.png';

// Banned Email list
import { bannedEmails } from '../../utils/banned_emails/bannedEmails';


// Initial State
const initialState = {
    email: ''
}

const AuthPage_Body = ({
    auth: { 
        isAuthenticated, 
        user,
        account_loading
    }, 
    nav: {
        campus_name,
        campus_ext
    },
    loginWithEmailAndPassword,
    checkIfUserExists,
    signInWithGoogle,
    setAlert,
    history,
    source,
    modal
}) => {
    
    // State for tracking the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // State for form data
    const [formData, setFormData] = useState(initialState);

    // State to toggle login methods
    const [loginWithPassword, setLoginWithPassword] = useState(true);

    // State to toggle email code inputs
    const [showEmailCode, setShowEmailCode] = useState(false);

    // Get the URL filter and check if there is a "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("redirect");
    const authMethod = url.searchParams.get("method");

    useEffect(() => {
        
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Handle the "redirect" parameter in the URL
    useEffect(() => {
        if(filter && filter === 'true') {
            setAlert('Failed: Wrong Email.', 'danger')
        } 

    }, [filter]);

    // Handle the Authentication Method via URL parameter
    useEffect(() => {
        if(authMethod && authMethod === 'password') {
            setLoginWithPassword(true)
        } 

        if(authMethod && authMethod === 'noPassword') {
            setLoginWithPassword(false)
        } 
    }, [authMethod]);

    // Function to handle window size change
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Function to go back in history
    const goBack = (e) => {
        history.goBack();
    }

    // Check if the device is mobile or tablet
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Destructure the email value from form data
    const { email, password } = formData;

    // Function to handle input change
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});

    // Function to validate email format
    const validateEmail = (input) => {

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      
        if (input.match(validRegex)) {
      
          return true;
      
        } else {
      
          return false;
      
        }
    }

    const handleEmailSignIn = e => {
        e.preventDefault();
        
        if(email === '') {
            setAlert('Email is required.', 'danger');
        } else if (!validateEmail(email)) {
            setAlert('Invalid email address!', 'danger');
        } else if (bannedEmails.includes(email)) {
            setAlert('Account has been banned', 'danger');
        } else {

            checkIfUserExists(email);
        }
    }

    // --- Campus Logic ----

    let app_logo;
    
    if(campus_name.toLowerCase() === 'campus') {
            
        app_logo = campus_logo
    }
    else if(campus_name.toLowerCase() === 'ou') {
            
        app_logo = ou_logo
    }
    else if(campus_name.toLowerCase() === 'ut') {
        app_logo = ut_logo
    }
    else if(campus_name.toLowerCase() === 'smu') {
        app_logo = smu_logo
    }
    else if(campus_name.toLowerCase() === 'uf') {
        app_logo = uf_logo
    }

    // --- END: Campus Logic ----

    return (

        <div className='register_container'>
            <div className={`register_content_container ${modal ? 'noPaddingTop' : ''}`}>
                <div className='register_content'>
                    <div style={{boxSizing:'border-box', textAlign:'center'}}>
                        
                        {/* Logo */}
                        {!modal && (
                            <div onClick={() => window.location = '/'} className='register_logo'>
                                <img src={app_logo} />
                            </div>
                        )}

                        {/* {source === 'comment modal' && (
                            <ChatBubbleIcon />
                        )} */}

                        <div style={{width: '100%', height: '1px', marginTop: '20px', background: '#e6ecf0'}}>
                            {/* spacer */}
                        </div>
                        
                    </div>
                    
                    <div style={{marginTop: '20px'}}>

                        <div>

                            <GoogleBtn />

                            <p style={{fontSize: '12px', color: '#808080', fontWeight: 600, marginTop: '1rem', textAlign: 'center'}}>
                                --Or continue with--
                            </p>
                        </div>

                        <div>

                            {/* Input fields */}
                            <div className='register_form_inputs'>       
                                {/* Input & Label */}
                                <div className='register_input_container login'>
                                    <label for="email" className='register_input_label login'>
                                        Enter email address
                                    </label>
                                    <input 
                                        // className='register_input_element login' 
                                        type="text"
                                        placeholder={`e.g., yourname@email.com`}
                                        className="input"
                                        name="email"
                                        value={email}
                                        // maxlength="30"
                                        onChange={e => onChange(e)}
                                    />
                                </div>
                            </div>

                        </div>

                        {/* /* Form Actions */}
                        <div className='register_btns'>
                            {!account_loading ? (
                                <Button 
                                    onClick={handleEmailSignIn} 
                                    className="sidebar__tweet black"
                                    fullWidth
                                >
                                    Continue with email
                                </Button>
                            ) : (
                                <Button 
                                    className="sidebar__tweet black"
                                    disabled={true}
                                    fullWidth
                                >
                                    processing...
                                </Button>
                            )}
                        </div>
                    </div>
                    
                </div>
            </div>

            {/* Page footer - fixed to bottom of screen */}
            <div className={`register_footer ${modal ? 'marginTop_20' : ''}`}>
                <hr style={{height:'1px', background:'rgb(232, 233, 235)'}} />
                <div className={`register_footer_nav ${modal === 'comment modal' ? 'flexDirection_col' : ''}`}>
                    <a href='/settings/terms'>
                    Read our privacy policy
                    </a>
                    <a href='/settings/help'>
                    Contact support
                    </a>
                </div>
            </div>
        </div>
        
        // <ModalPage>
        //     {/* <Alert /> */}

        //     <div style={{margin: '15px 10px'}} onClick={goBack} className="form__backBtn">
        //         <ArrowBackIcon />
        //     </div>
                
        //     <Logo />

        //     <div>
        //         <h2>Log in with your email</h2>
        //         <p>Enter your email to continue</p>
        //     </div>
        //     <div className="modal__inputs">
        //         <input
        //             type="text"
        //             placeholder="Email"
        //             className="input"
        //             name="email"
        //             value={email}
        //             onChange={e => onChange(e)}
        //         />
        //         {/* <input
        //             type="password"
        //             placeholder="Password"
        //             className="input"
        //             name="password"
        //             value={password}
        //             onChange={e => onChange(e)}
        //         /> */}
        //     </div>
        //     <div>
        //         <Button 
        //             variant="outlined" 
        //             className="sidebar__tweet" 
        //             fullWidth
        //             onClick={onSubmit}
        //         >
        //             Continue
        //         </Button>
        //     </div>
        //     <div className="auth__footer">
        //         <a href={`/register`}>Don't have one?</a>
        //         <a href={`/register`}>Create an account</a>
        //     </div>
        // </ModalPage>
    )
}

// Prop types for the Login component
AuthPage_Body.propTypes = {
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
    loginWithEmailAndPassword: PropTypes.func.isRequired,
    checkIfUserExists: PropTypes.func.isRequired,
    signInWithGoogle: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    loginWithEmailAndPassword,
    setAlert,
    checkIfUserExists,
    signInWithGoogle
})(AuthPage_Body);
