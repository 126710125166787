import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleSideNav, setPage, toggleUserModal, add_To_Recent_History } from '../actions/navActions';
import { setUsers } from '../actions/authActions';
import { getUserChats, createChat, getChatById } from '../actions/chatActions';

// Modal
import UserModal from '../components/modal/UserModal';
import ModalContainer from '../components/modal/ModalContainer';

// Components - imported
import { Button } from '@material-ui/core';
import ChatBoxNav from '../components/ChatBox/Nav_ChatBox';

import UserTable from '../components/Tables/UserTable/UserTable';

// Icons - material UI
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

const ChatNav = ({ 
    toggleSideNav, 
    toggleUserModal, 
    setPage, 
    add_To_Recent_History,
    getUserChats, 
    getChatById, 
    auth, 
    nav: { 
        sideNav 
    }, 
    chat, 
    history 
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Check if user is verified
    const [checkedVerification_schoolEmail, setCheckedVerification_schoolEmail] = useState(false);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    useEffect(() => {

        // Set current page to 'messages' in navigation state
        setPage('messages');
        add_To_Recent_History(url.pathname);  // current url

    }, []);

    useEffect(() => {


        // Fetch user chats
        if(auth.isAuthenticated && !auth.loading) {
            getUserChats();
        }


    }, [auth.user]);

    // Function to handle Mixpanel tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.identify(auth.user._id);
        mixpanel.track("Chat Nav Page View");
    }

    // Track Mixpanel event when the component mounts
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && auth.user !== null) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    // Check if user's school email is verified. If no redirect to school email page
    if(auth.user && !auth.user.school_verified && !checkedVerification_schoolEmail) {
        window.location.href = '/settings/account/school_email';

        setCheckedVerification_schoolEmail(true);
    }

    return (
        <Fragment>
            <div className="inbox__container">
                <ChatBoxNav chat={chat} getChatById={getChatById} sideNav={sideNav} handleSlideMenu={toggleSideNav} toggleUsernameModal={toggleUserModal} history={history} />   
            </div>
        </Fragment>
    )
}

ChatNav.propTypes = {
    toggleSideNav: PropTypes.func.isRequired, // Function to toggle the side navigation
    toggleUserModal: PropTypes.func.isRequired, // Function to toggle the user modal
    setPage: PropTypes.func.isRequired, // Function to set the page
    add_To_Recent_History: PropTypes.func.isRequired,
    setUsers: PropTypes.func.isRequired, // Function to set the users
    getUserChats: PropTypes.func.isRequired, // Function to get user chats
    createChat: PropTypes.func.isRequired, // Function to create a chat
    getChatById: PropTypes.func.isRequired, // Function to get a chat by its ID
    nav: PropTypes.object.isRequired, // Object representing the navigation state
    auth: PropTypes.object.isRequired, // Object representing the authentication state
    chat: PropTypes.object.isRequired, // Object representing the chat state
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    nav: state.nav,
    auth: state.auth,
    chat: state.chat
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    toggleSideNav, 
    toggleUserModal, 
    setPage, 
    add_To_Recent_History,
    setUsers, 
    getUserChats, 
    getChatById, 
    createChat 
})(ChatNav);
