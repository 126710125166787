import React, { Fragment, useEffect, useState } from 'react';

import mixpanel from 'mixpanel-browser';

// components - material UI
import { Button } from '@material-ui/core';

// Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const About_Settings = ({ 
    auth,
    chat,
    notification,
    getUnreadChats,
    getUnreadCount,
    history,
    campus_name,
    goBack
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        // Fetch unread counts for notifications and chats
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user]);

    useEffect(() => {

        // Update the badge value to total (Notifications + unread Chats)
        setBadgeValue(notification.num_unread + chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Initialize Mixpanel and track settings About page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings About Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <div className="settings">

            {/* Header */}
            <div className="settings__header">
                {isTablet && (

                    // Back button & Badge value
                    <div onClick={goBack} className="profile__btnContainer">
                        <ArrowBackIcon />
                        {isMobile && badgeValue > 0 ? (
                            <span className="feed-header-badge defaultBadge">
                                {badgeValue}
                            </span>
                        ) : null}
                    </div>
                )}

                {/* Title */}
                <div className="settings__headerText">
                    <h3>
                        About
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            <div className="settings__body terms">
                <div className="trust-and-safety">
                    <h2 class="h1 mb-2 text-center">The simplest way to Buy / Sell at {campus_name}.</h2>
                    <hr class="hr-white" />
                    <h4 style={{marginTop:'30px'}}>Browse tons of great items nearby.</h4>
                    <div class="text-editor mb-4">
                        <p style={{textAlign:'center'}}>
                            like furniture, clothes, and electronics — and buy from your classmates.
                        </p>
                    </div>
                    <hr class="hr-white" />
                    <h4 style={{marginTop:'30px'}}>Instantly message sellers.</h4>
                    <div class="text-editor mb-4">
                        <p style={{textAlign:'center'}}>
                            securely on the website to negotiate price and set up a time to meet.
                        </p>
                    </div>
                    <hr class="hr-white" />
                    <h4 style={{marginTop:'30px'}}>Check out people’s profiles.</h4>
                    <div class="text-editor mb-4">
                        <p style={{textAlign:'center'}}>
                            to see who they are, and what they are selling.
                        </p>
                    </div>
                    <hr class="hr-white" />
                    <h4 style={{marginTop:'30px'}}>Sell something of your own.</h4>
                    <div class="text-editor mb-4">
                        <p style={{textAlign:'center'}}>
                            by simply taking a picture with your phone. Posting takes less than 30 seconds!
                        </p>
                    </div>
                    <hr class="hr-white" />
                    <h4 style={{marginTop:'30px'}}>Join thousands of students.</h4>
                    <div class="text-editor mb-4">
                        <p style={{textAlign:'center'}}>
                            on the largest groupchat for students to buy and sell at {campus_name}.
                        </p>
                    </div>
                    <hr class="hr-white" />
                    <h5 style={{marginTop:'30px'}}>NEED HELP?</h5>
                    <h3>REACH US ON Social</h3>
                    <div>
                        <p>We are here to help solve problems and investigate issues when they arise. Please contact us for assistance.</p>
                        <Button onClick={() => window.open(`https://www.instagram.com/${campus_name.toLowerCase()}buysell/`, "_blank")} variant="outlined" className="sidebar__tweet ghost" fullWidth>DM us @{campus_name}BuySell</Button>
                    </div>
                </div>
            </div>
            <div className="no-rides" />
        </div>
    )
}

export default About_Settings;
